export const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Create a new date using UTC methods
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
    const day = date.getUTCDate();

    return `${month} ${day}, ${year}`;
};

export const formatTime = (timeString) => {
    if (timeString) {
        const [hours, minutes] = timeString.split(':');
        let formattedHours = parseInt(hours, 10) % 12;
        formattedHours = formattedHours === 0 ? 12 : formattedHours;
        const period = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
        return `${formattedHours}:${minutes} ${period}`;
    }
}